import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';
import { AsHeading } from 'types/common';

import { ControlInput, InputControlOption, InputControlProps } from '../ControlInput';
import { SelectGroupType as RadioGroupType } from './RadioGroup';
import { SelectGroupTitle } from './SelectGroupTitle';

type ControlGroupType = {
  options: InputControlOption[];
} & RadioGroupType &
  AsHeading &
  Pick<InputControlProps, 'intent' | 'whiteBackground'>;

type Props = ControlGroupType & ParentClassNameProp;

const useStyles = makeStyles({
  wrapper: (props: ParentClassNameProp) => [props.className]
});

export const ControlGroup = memo(({ title, className, as, ...props }: Props) => {
  const styles = useStyles({ className });
  return (
    <div className={styles.wrapper}>
      {title && <SelectGroupTitle as={as} title={title} />}
      <ControlInput {...props} />
    </div>
  );
});

ControlGroup.displayName = 'ControlGroup';
