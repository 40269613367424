import { Transition } from '@headlessui/react';
import { transformGPUShadowTransition } from 'lib/transitionProps/transitionProps';
import React from 'react';

type Props = {
  open?: boolean;
} & ParentClassNameProp &
  ChildrenProp;

const DropdownOpenTransition: React.FC<Props> = ({ children, open, className }) => {
  return (
    <Transition show={open} className={className} {...transformGPUShadowTransition}>
      {children}
    </Transition>
  );
};

DropdownOpenTransition.displayName = 'DropdownOpenTransition';

export { DropdownOpenTransition };
