import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TruckWeightIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_238)">
      <path
        d="M10.3307 6.95834H14.003M1.99734 6.95834H5.66965M8.00016 11.9583V2.79168M8.00016 2.79168V1.95834M8.00016 2.79168H1.9585M8.00016 2.79168H14.0418M12.1668 2.79168L13.895 6.63209C13.9918 6.84718 14.0418 7.08026 14.0418 7.31609V7.37501C14.0418 8.29551 13.2957 9.04168 12.3752 9.04168H11.9585C11.038 9.04168 10.2918 8.29551 10.2918 7.37501V7.31609C10.2918 7.08026 10.3418 6.84718 10.4387 6.63209L12.1668 2.79168ZM3.8335 2.79168L5.5617 6.63209C5.65846 6.84718 5.7085 7.08026 5.7085 7.31609V7.37501C5.7085 8.29551 4.9623 9.04168 4.04183 9.04168H3.62516C2.70469 9.04168 1.9585 8.29551 1.9585 7.37501V7.31609C1.9585 7.08026 2.00853 6.84718 2.1053 6.63209L3.8335 2.79168ZM10.7085 14.0417V13.625C10.7085 12.7045 9.96233 11.9583 9.04183 11.9583H6.9585C6.03802 11.9583 5.29183 12.7045 5.29183 13.625V14.0417H10.7085Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_238">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TruckWeightIcon.displayName = 'TruckWeightIcon';

export { TruckWeightIcon };
