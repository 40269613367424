import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const FuelTypeIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_263)">
      <path
        d="M9.37492 14.4167C9.37492 14.7964 9.68273 15.1042 10.0624 15.1042C10.4421 15.1042 10.7499 14.7964 10.7499 14.4167H9.37492ZM1.58325 14.4167C1.58325 14.7964 1.89106 15.1042 2.27075 15.1042C2.65044 15.1042 2.95825 14.7964 2.95825 14.4167H1.58325ZM4.10409 3.875H8.22908V2.5H4.10409V3.875ZM9.37492 5.02083V14.4167H10.7499V5.02083H9.37492ZM2.95825 14.4167V5.02083H1.58325V14.4167H2.95825ZM8.22908 3.875C8.86195 3.875 9.37492 4.388 9.37492 5.02083H10.7499C10.7499 3.62862 9.62132 2.5 8.22908 2.5V3.875ZM4.10409 2.5C2.71187 2.5 1.58325 3.62862 1.58325 5.02083H2.95825C2.95825 4.388 3.47126 3.875 4.10409 3.875V2.5Z"
        fill="currentColor"
      />
      <path
        d="M5.02075 5.9375V5.25C4.64106 5.25 4.33325 5.55781 4.33325 5.9375H5.02075ZM7.31242 5.9375H7.99992C7.99992 5.55781 7.6921 5.25 7.31242 5.25V5.9375ZM7.31242 9.14583V9.83333C7.6921 9.83333 7.99992 9.52552 7.99992 9.14583H7.31242ZM5.02075 9.14583H4.33325C4.33325 9.52552 4.64106 9.83333 5.02075 9.83333V9.14583ZM5.02075 6.625H7.31242V5.25H5.02075V6.625ZM6.62492 5.9375V9.14583H7.99992V5.9375H6.62492ZM5.70825 9.14583V5.9375H4.33325V9.14583H5.70825ZM5.02075 9.83333H7.31242V8.45833H5.02075V9.83333Z"
        fill="currentColor"
      />
      <path
        d="M1.35425 13.9583C0.974556 13.9583 0.666748 14.2661 0.666748 14.6458C0.666748 15.0255 0.974556 15.3333 1.35425 15.3333V13.9583ZM10.9792 15.3333C11.3589 15.3333 11.6667 15.0255 11.6667 14.6458C11.6667 14.2661 11.3589 13.9583 10.9792 13.9583V15.3333ZM1.35425 15.3333H10.9792V13.9583H1.35425V15.3333Z"
        fill="currentColor"
      />
      <path
        d="M10.2917 10.9792C9.91206 10.9792 9.60425 11.287 9.60425 11.6667C9.60425 12.0463 9.91206 12.3542 10.2917 12.3542V10.9792ZM13.0441 5.90617L12.5302 5.44941L13.0441 5.90617ZM15.1598 4.5609C15.412 4.27712 15.3865 3.84256 15.1027 3.5903C14.8189 3.33805 14.3843 3.36361 14.132 3.6474L15.1598 4.5609ZM10.2917 12.3542H10.9792V10.9792H10.2917V12.3542ZM13.5001 9.83332V6.51517H12.1251V9.83332H13.5001ZM13.5579 6.36291L15.1598 4.5609L14.132 3.6474L12.5302 5.44941L13.5579 6.36291ZM13.5001 6.51517C13.5001 6.45907 13.5207 6.40489 13.5579 6.36291L12.5302 5.44941C12.2693 5.74306 12.1251 6.12228 12.1251 6.51517H13.5001ZM10.9792 12.3542C12.3715 12.3542 13.5001 11.2256 13.5001 9.83332H12.1251C12.1251 10.4662 11.6121 10.9792 10.9792 10.9792V12.3542Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_263">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

FuelTypeIcon.displayName = 'FuelTypeIcon';

export { FuelTypeIcon };
