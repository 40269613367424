import { IUserMeta } from '@truckmap/common';
import { atom } from 'recoil';
import { persistanceCacheEffect } from 'recoil/persistanceCacheEffect';

import { syncUserMetasEffect } from './userMetasAtomEffects';

export type UserMetasAtom = Partial<IUserMeta>;

export const userMetasAtom = atom<UserMetasAtom>({
  key: 'userMetasAtom',
  default: {},
  effects: [persistanceCacheEffect.persistAtom, syncUserMetasEffect]
});
