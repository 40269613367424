import { ITruckOptionsItemKeys } from '@truckmap/common';
import { atom } from 'recoil';

export type TruckOptionsModalAtomType = {
  modalOpen: boolean;
  activeModalTitle: string | null;
  activeOption: ITruckOptionsItemKeys;
};

export const truckOptionsModalAtom = atom<TruckOptionsModalAtomType>({
  key: 'truckOptionsModalAtom',
  default: {
    modalOpen: false,
    activeModalTitle: null,
    activeOption: null
  }
});
