import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CheckboxInput, SelectInputProps as CheckboxInputProps } from '../SelectInput';
import {
  InputOptionType as RadioOptionType,
  SelectGroupType as RadioGroupType
} from './RadioGroup';
import { SelectGroupTitle } from './SelectGroupTitle';

export type InputOptionType = {
  indeterminate?: boolean;
  icon?: React.ReactNode;
} & RadioOptionType &
  Required<Pick<JSX.IntrinsicElements['input'], 'name'>>;

export type Props = {
  options: InputOptionType[];
  locked?: boolean;
  onLockedClick?: () => void;
} & RadioGroupType;

const useStyles = makeStyles({
  wrapper: (props: Props) => [
    'py-1',
    {
      [`sm:grid sm:grid-cols-${props.col?.toString() ?? '1'}`]: props.col
    }
  ]
});

export const CheckboxGroup = ({
  borderless = true,
  title,
  options,
  col,
  locked,
  onLockedClick
}: Props) => {
  const styles = useStyles({ col });
  return (
    <div className={styles.wrapper}>
      {title && <SelectGroupTitle title={title} />}
      {options.map((option, index) => {
        const {
          label,
          disabled,
          indeterminate,
          name,
          required,
          checked,
          defaultChecked,
          ...inputProps
        } = option;
        const { control, setValue } = useFormContext();
        return (
          <PanelItem
            key={`${name}_${index}_`}
            enableBorder={!borderless}
            active={checked || defaultChecked}
            disableFlex
            enableBackground
            intent="primary"
            enableHover
            paddingX="XS"
            paddingY="XS"
            rounded="SM">
            <Controller
              name={name}
              defaultValue={checked}
              rules={{ required }}
              control={control}
              render={({ field: { value, ref } }) => (
                <CheckboxInput
                  disabled={disabled}
                  indeterminate={indeterminate}
                  name={`checkbox_${name}_${index}_`}
                  checked={value}
                  // this onChange callback is here to avoid "react fewer hooks" issue if using useController
                  onChange={() => {
                    if (!value && locked) {
                      onLockedClick();

                      return;
                    }

                    return setValue(name, !value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true
                    });
                  }}
                  {...(inputProps as CheckboxInputProps)}
                  ref={ref}>
                  {label ?? value}
                </CheckboxInput>
              )}
            />
          </PanelItem>
        );
      })}
    </div>
  );
};

CheckboxGroup.displayName = 'CheckboxGroup';
