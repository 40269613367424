import { InfoIcon } from 'icons/alert/InfoIcon';
import { SuccessIcon } from 'icons/alert/SuccessIcon';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';

import { SmallText } from '../Typography';
import { TooltipCaret } from './TooltipCaret';

export type TooltipProps = {
  message: string;
  isSuccess?: boolean;
  showIcon?: boolean;
  isSquare?: boolean;
  isSmall?: boolean;
} & ParentClassNameProp;

const useStyles = makeStyles<TooltipProps>()({
  tooltip: (props) => [
    'mx-auto',
    'shadow',
    'z-public-navigation',
    {
      'rounded-full': !props.isSquare,
      rounded: props.isSquare
    },

    {
      'py-2': !props.isSmall,
      'px-4': !props.isSmall
    },
    {
      'py-1': props.isSmall,
      'px-2': props.isSmall
    },
    {
      'w-full': !props.isSquare,
      'w-[260px]': props.isSquare && !props.isSmall
    },
    {
      'bg-primaryBackgroundDark': !props.isSuccess,
      'bg-successBackgroundLoud': props.isSuccess
    },
    props.className
  ],
  tooltipCaret: (props) => [
    'absolute',
    'h-2',
    'w-full',
    'left-0',
    {
      'top-10': props.isSquare,
      'top-full': !props.isSquare
    },
    {
      // to match tooltip background
      'text-primaryBackgroundDark': !props.isSuccess,
      'text-successBackgroundLoud': props.isSuccess
    }
  ],
  showIcon: (props) => [
    'mr-1',
    {
      hidden: !props.showIcon,
      block: props.showIcon
    }
  ],
  message: (props) => [
    'w-full',
    {
      'whitespace-nowrap': !props.isSquare || props.isSmall
    },
    'space-x-3'
  ],
  flex: 'flex',
  icon: 'text-white'
});

const Tooltip = memo((props: TooltipProps) => {
  const styles = useStyles(props);
  const tooltipIcon = props.isSuccess ? (
    <SuccessIcon className={styles.icon} />
  ) : (
    <InfoIcon className={styles.icon} />
  );

  return (
    <div className={styles.tooltip} aria-label={props.message} role="dialog">
      <div className={styles.flex}>
        <div className={styles.showIcon}>{tooltipIcon}</div>
        <SmallText color="white" className={styles.message} whitespace="nowrap">
          {props.message}
        </SmallText>
      </div>
      {!props.isSquare && <TooltipCaret className={styles.tooltipCaret} />}
    </div>
  );
});

Tooltip.displayName = 'Tooltip';

export { Tooltip };
