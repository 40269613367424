import { sortObject } from '@truckmap/common';
import { InputOptionType } from 'components/common/Input/SelectInputGroup/RadioGroup';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

// TODO: documentation
const useCustomGroupInputType = (options: InputOptionType[], groupKey: string) => {
  if (isEmpty(options)) {
    return {};
  }
  return useMemo(() => sortObject({ items: options, groupKey }), [options]);
};

export { useCustomGroupInputType };
