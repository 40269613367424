import { ITruckOptionsPayload } from '@truckmap/common';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userMetasAtom } from 'recoil/user';

export const useCurrentUserOptions = ({
  truckOptionsPayload
}: {
  truckOptionsPayload: ITruckOptionsPayload;
}) => {
  const userMetas = useRecoilValue(userMetasAtom);

  return useMemo(() => {
    if (!truckOptionsPayload || !userMetas || !Object.keys(truckOptionsPayload).length) return;

    const finalObject = {};
    Object.keys(truckOptionsPayload.truckOptionsList).forEach((key) => {
      const currentOptionList = truckOptionsPayload.truckOptionsList[key];
      const id =
        userMetas[key] || currentOptionList.find(({ default: isDefault }) => isDefault)?.id;
      const optionItem = currentOptionList.find(({ id: currentId }) => currentId === id);

      finalObject[key] = optionItem;
    });

    return finalObject;
  }, [truckOptionsPayload, userMetas]);
};
