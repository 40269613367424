import { memo } from 'react';

type Props = {
  className: string;
};

export const TooltipCaret = memo(({ className }: Props) => {
  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
      <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
    </svg>
  );
});

TooltipCaret.displayName = 'ToolTipMessage/TooltipCaret';
