import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { ModalProps, StyledProps } from '../Modal';

type ModalInnerProps = Pick<ModalProps, 'childrenClassName'> & ChildrenProp;

export const useStyles = makeStyles({
  formChildren: (props: StyledProps) => ['p-2', props.childrenClassName]
});

const ModalInner: React.ComponentType<ModalInnerProps> = React.memo(
  ({ children, childrenClassName }) => {
    const styles = useStyles({ childrenClassName });

    return childrenClassName ? (
      <div className={styles.formChildren}>{children}</div>
    ) : (
      <>{children}</>
    );
  }
);

ModalInner.displayName = 'ModalInner';

export { ModalInner };
