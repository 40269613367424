import { makeStyles } from 'lib/makeStyles';
import React, { memo, ReactNode } from 'react';

import { ControlValue } from './ControlValue';

export type InputControlProps = {
  options: InputControlOption[];
  name?: string;
  intent?: 'primary' | 'secondary';
  whiteBackground?: boolean;
  onClickControlled?: (value: string | number | boolean) => void;
} & Pick<JSX.IntrinsicElements['input'], 'onChange'>;

export type InputControlOption = {
  node?: ReactNode;
  hasNodeLink?: boolean;
  value: string | number;
  checked?: boolean;
  disabled?: boolean;
} & Pick<InputControlProps, 'onClickControlled'>;

const useStyles = makeStyles({
  root: ['relative', 'inline-flex', 'shadow-sm', 'rounded-md', 'filter']
});

const ControlInput = memo(
  ({ options, name, onChange, onClickControlled, intent, whiteBackground }: InputControlProps) => {
    const { root } = useStyles();

    return (
      <label className={root}>
        {options?.map((option, index) => (
          <ControlValue
            key={`${name}-${index}`}
            split={index < options.length - 1}
            name={name}
            onChange={onChange}
            onClickControlled={onClickControlled}
            hasNodeLink={option.hasNodeLink}
            checked={option.checked}
            value={option.value}
            disabled={option.disabled}
            intent={intent}
            rounded={index === 0 ? 'left' : index === options.length - 1 ? 'right' : false}
            whiteBackground={whiteBackground}>
            {option.node ?? option.value}
          </ControlValue>
        ))}
      </label>
    );
  }
);

ControlInput.displayName = 'ControlInput';

export { ControlInput };
