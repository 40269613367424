import { Button } from 'components/common/Button';
import { useModalContext } from 'components/common/Modal';
import { Translation } from 'components/common/Translation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { FieldValues, useFormContext, UseFormStateReturn } from 'react-hook-form';

import { ModalSelectGroupProps } from './ModalSelectGroup';

export type ModalFooterProps = Pick<ModalSelectGroupProps, 'submitLabel'> & {
  onReset?: () => void;
  shouldDisableSubmit?: (formState: UseFormStateReturn<FieldValues>) => boolean;
};

const useStyles = makeStyles({
  buttonContainer: ['grid grid-cols-2 gap-2', 'pt-4 pb-1'],
  button: ['w-full']
});

export const ModalFooter: React.ComponentType<ModalFooterProps> = React.memo(
  ({ submitLabel, onReset, shouldDisableSubmit }) => {
    const styles = useStyles();
    const { formState } = useFormContext();
    const { toggleState } = useModalContext();

    const handleDisableSubmit = React.useCallback(
      () => shouldDisableSubmit(formState),
      [formState, shouldDisableSubmit]
    );

    return (
      <div className={styles.buttonContainer} key={JSON.stringify(formState)}>
        <Button
          type="reset"
          onClick={toggleState?.disable || onReset}
          className={styles.button}
          fullWidth>
          <Translation textKey="BUTTON_CANCEL" />
        </Button>
        <Button disabled={handleDisableSubmit()} className={styles.button} isPrimary fullWidth>
          {submitLabel ?? <Translation textKey="BUTTON_SUBMIT" />}
        </Button>
      </div>
    );
  }
);

ModalFooter.displayName = 'ModalFooter';

ModalFooter.defaultProps = {
  shouldDisableSubmit(formState) {
    return !formState.isValid;
  }
};
