import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { AsHeading } from 'types/common';

import { MetaText } from '../../Typography';

type Props = {
  title: string;
} & AsHeading &
  ParentClassNameProp;

const useStyles = makeStyles<ParentClassNameProp>()({
  root: (props) => ['my-2', props?.className]
});

export const SelectGroupTitle = ({ title, as, className }: Props) => {
  const styles = useStyles({ className });

  return (
    <div className={styles.root}>
      <MetaText bold as={as}>
        {title}
      </MetaText>
    </div>
  );
};

SelectGroupTitle.defaultProps = {
  as: 'h4'
};

SelectGroupTitle.displayName = 'SelectGroupTitle';
