import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SmallText } from '../../Typography';
import { Props as RadioGroupProps } from './RadioGroup';

export type Props = {
  title: string;
  col?: number;
} & ChildrenProp &
  Pick<RadioGroupProps, 'hasCustomGroup'>;

const useStyles = makeStyles({
  root: ({ hasCustomGroup }) => ['w-full', { 'mb-3': hasCustomGroup }],
  title: ['text-paragraph0-700 mb-1'],
  children: (props: Props) => [
    'w-full',
    {
      [`sm:grid sm:grid-cols-${props.col?.toString() ?? '1'}`]: props.col
    }
  ]
});

export const SelectGroupSection: React.FC<Props> = ({
  children,
  col,
  title,
  hasCustomGroup = false
}) => {
  const styles = useStyles({ col, hasCustomGroup });
  return (
    <div className={styles.root}>
      {hasCustomGroup && (
        <SmallText bold className={styles.title}>
          {title}
        </SmallText>
      )}
      <div className={styles.children}>{children}</div>
    </div>
  );
};

SelectGroupSection.displayName = 'SelectGroupSection';
