import { makeStyles } from 'lib/makeStyles';
import React from 'react';

type Props = {
  open: boolean;
} & ChildrenProp;

export const useStyles = makeStyles<Props>()({
  transition: ({ open }: Props) => [
    'transition-transform duration-20',
    {
      '-rotate-180': open,
      'rotate-0': !open
    }
  ]
});

const DropdownIconTransition: React.FC<Props> = ({ open, children }) => {
  const styles = useStyles({ open });
  return <div className={styles.transition}>{children}</div>;
};

DropdownIconTransition.displayName = 'DropdownIconTransition';

export { DropdownIconTransition };
