import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TrailerTypeIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_213)">
      <path
        d="M4.5625 13.7292H6.69659"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4375 13.7292H14.6458"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.27075 10.9792V4.10417C2.27075 3.59791 2.68116 3.1875 3.18742 3.1875H11.8958C12.402 3.1875 12.8124 3.59791 12.8124 4.10417V13.7292H10.2916"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.95841 14.6458C3.84437 14.6458 4.56258 13.9276 4.56258 13.0417C4.56258 12.1557 3.84437 11.4375 2.95841 11.4375C2.07246 11.4375 1.35425 12.1557 1.35425 13.0417C1.35425 13.9276 2.07246 14.6458 2.95841 14.6458Z"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45841 14.6458C9.34437 14.6458 10.0626 13.9276 10.0626 13.0417C10.0626 12.1557 9.34437 11.4375 8.45841 11.4375C7.57246 11.4375 6.85425 12.1557 6.85425 13.0417C6.85425 13.9276 7.57246 14.6458 8.45841 14.6458Z"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02075 7.3125H9.14575"
        stroke="currentColor"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_213">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TrailerTypeIcon.displayName = 'TrailerTypeIcon';

export { TrailerTypeIcon };
