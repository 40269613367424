import { atomFamily } from 'recoil';

type AutoCompleteSearchAtom = {
  open: boolean;
  visible: boolean;
  value: string;
};

export type AutoCompleteType = 'publicSearch' | 'waypoint' | 'routePlanner';

export const autoCompleteAtom = atomFamily<AutoCompleteSearchAtom, AutoCompleteType>({
  key: 'autoCompleteAtom',
  default: {
    open: false,
    visible: false,
    value: ''
  }
});
