import { ModalFooterWrapper } from 'components/common/Modal/ModalContent';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { useFlexColumnLayoutStyles } from 'styles';

import { BackButton, CloseButton } from '../../Button';
import { ModalProps, StyledProps } from '../Modal';
import { ModalInner } from './ModalInner';
import { ModalTitle } from './ModalTitle';

type ModalContentProps = Pick<
  ModalProps,
  | 'childrenClassName'
  | 'contentClassName'
  | 'footer'
  | 'title'
  | 'onClose'
  | 'showClose'
  | 'showBack'
  | 'onBack'
> &
  ChildrenProp;

const useStyles = makeStyles({
  closeButton: ['absolute top-1 right-2.5', 'z-10'],
  backButton: ['absolute top-0 left-0 z-10'],
  content: (props: StyledProps) => [
    'relative',
    'flex w-full h-full',
    'px-2',
    'pt-1',
    props.contentClassName
  ]
});

export const ModalContent: React.ComponentType<ModalContentProps> = React.memo((props) => {
  const {
    title,
    childrenClassName,
    footer,
    children,
    onClose,
    showClose,
    showBack,
    onBack,
    contentClassName
  } = {
    ...props
  };

  const styles = useStyles({ contentClassName });
  const flexLayoutStyles = useFlexColumnLayoutStyles({ disableYSpacing: true });

  return (
    <div className={styles.content}>
      {showClose && <CloseButton className={styles.closeButton} onClose={onClose} />}
      {showBack && (
        <div className={styles.backButton}>
          <BackButton circle="SM" onClick={onBack ?? onClose} />
        </div>
      )}
      <div className={flexLayoutStyles.flexContainer}>
        {title && <ModalTitle title={title} />}
        <ModalInner childrenClassName={childrenClassName}>{children}</ModalInner>
        {footer && <ModalFooterWrapper footer={footer} />}
      </div>
    </div>
  );
});

ModalContent.displayName = 'ModalContent';
