import { IconProps } from 'components/common/AssetIcon';

const iconSizeMap = new Map<string, number>([
  ['LG', 64],
  ['MD', 42],
  ['SM', 26]
]);

export const getIconSize = ({ size }: Pick<IconProps, 'size'>) => {
  return iconSizeMap.get(size) || iconSizeMap.get('SM');
};
