import cx from 'clsx';
import { useSkeletonVariantStyles } from 'components/common/Skeleton/styles';
import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { getIconSize } from 'lib/getIconSize';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type SkeletonProps = ParentClassNameProp & {
  width?: 'SM' | 'MD' | 'LG' | 'full';
  height?: 'SM' | 'MD' | 'LG' | 'XL' | false;
  animate?: boolean;
  type?: 'grid' | 'square';
};

export const useSkeletonStyles = makeStyles<SkeletonProps>()({
  rectangleSkeleton: ({ width, height, className }) => [
    {
      'max-w-sm': width === 'LG',
      'max-w-xs': width === 'MD',
      'max-w-[120px]': width === 'SM',
      'w-full': width === 'full',
      'h-8': height === 'XL',
      'h-6': height === 'LG',
      'h-4': height === 'MD',
      'h-2': height === 'SM'
    },
    className
  ],
  squareSkeleton: ({ className }) => [className]
});

export const Skeleton = React.memo(
  ({ animate = true, width = 'MD', height = 'MD', type = 'grid', className }: SkeletonProps) => {
    const isSquare = type === 'square';
    const basicSkeletonStyles = useSkeletonVariantStyles()({
      intent: 'primary',
      mode: 'default',
      interaction: 'hover'
    });
    const styles = useSkeletonStyles({
      animate,
      width,
      height,
      className: cx(basicSkeletonStyles, className)
    });
    const iconSize = isSquare && getIconSize({ size: width === 'full' ? 'LG' : width });
    return (
      <OpacityTransition
        show={animate}
        className={isSquare ? styles.squareSkeleton : styles.rectangleSkeleton}
        style={{ height: isSquare && iconSize, width: isSquare && iconSize }}
      />
    );
  }
);

Skeleton.displayName = 'Skeleton';
