import { makeVariantStyles } from 'lib/makeStyles';
import { useMemo } from 'react';

export const makeSkeletonVariantStyles = () =>
  makeVariantStyles(
    {
      compoundVariants: [
        {
          intent: 'primary',
          mode: 'default',
          interaction: 'hover',
          class: ['bg-neutralBackgroundLoud/50']
        }
      ]
    },
    ['relative block', 'w-full', 'rounded']
  );

export const useSkeletonVariantStyles = () => useMemo(() => makeSkeletonVariantStyles(), []);
