import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { useFormState } from 'react-hook-form';

import { Button } from '../Button';

export type Props = {
  isSmall?: boolean;
  disabled?: boolean;
  label?: string;
  defaultPadding?: boolean;
};

const useStyles = makeStyles({
  container: (props: Props) => [
    {
      'p-1': props.defaultPadding
    }
  ]
});

const FormSubmitButton = ({ isSmall, disabled, label, defaultPadding }: Props) => {
  const styles = useStyles({ defaultPadding });
  const { t } = useTranslation();
  const { isValid } = useFormState();

  return (
    <div className={styles.container}>
      <Button isSmall={isSmall} disabled={!isValid ?? disabled} isPrimary fullWidth>
        {label ?? t('BUTTON_SUBMIT')}
      </Button>
    </div>
  );
};

FormSubmitButton.defaultProps = {
  defaultPadding: true
};

FormSubmitButton.displayName = 'FormSubmitButton';

export { FormSubmitButton };
