import { Transition } from '@headlessui/react';
import { tooltipOpacityTransition } from 'lib/transitionProps';
import React, { memo, useMemo } from 'react';

export type TooltipMotionProps = {
  visible: boolean;
  offsetX?: number;
  offsetY?: number;
} & ChildrenProp;

const TooltipMotion: React.FC<TooltipMotionProps> = memo(
  ({ children, visible = false, offsetX = 0, offsetY = 0 }) => {
    const offsetStyle = useMemo(
      () =>
        offsetX || offsetY
          ? {
              transform: `translate(-${offsetX}px, -${offsetY}px)`
            }
          : undefined,
      [offsetX, offsetY]
    );

    const transitionElement = (
      <Transition show={visible} {...tooltipOpacityTransition}>
        {children}
      </Transition>
    );

    return offsetStyle ? <div style={offsetStyle}>{transitionElement}</div> : transitionElement;
  }
);

TooltipMotion.displayName = 'TooltipMotion';

export { TooltipMotion };
