import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

const config: MutationObserverInit = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true
};

/**
 * @param {MutableRefObject<HTMLElement | null>} ref React ref on which mutations are to be observed
 * @param {MutationCallback} callback Function that needs to be fired on mutation
 * @param {MutationObserverInit} options
 * @returns useEffect with a mutation observer for a React Ref and fires a callback
 */
function useMutationObserver(
  ref: MutableRefObject<HTMLElement | null>,
  callback: MutationCallback,
  options: MutationObserverInit = config
): void {
  useEffect(() => {
    if (ref?.current) {
      const observer = new MutationObserver(callback);
      observer?.observe(ref.current, options);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, callback, options]);
}

export { useMutationObserver };
