import { fetchUserMetas, updateUserMetas } from '@truckmap/common';
import { AtomEffect } from 'recoil';

import { userAtom, UserMetasAtom } from '.';

export const syncUserMetasEffect: AtomEffect<UserMetasAtom> = ({
  setSelf,
  onSet,
  trigger,
  getLoadable
}) => {
  const user = getLoadable?.(userAtom).getValue();

  if (user && trigger === 'get') {
    fetchUserMetas().then((userMetas) => {
      setSelf((localOptions) => ({
        ...localOptions,
        ...userMetas
      }));
    });
  }

  onSet(async (newTruckOptionsValues) => {
    // we need to always get the latest user state on this callback
    const currentUserState = getLoadable(userAtom).getValue();

    if (currentUserState) {
      updateUserMetas(newTruckOptionsValues);
    }
  });
};
