import { SelectItemProps } from 'components/common/SelectItem/SelectItem';
import { MetaText } from 'components/common/Typography';
import { makeStyles } from 'lib/makeStyles/makeStyles';
import React, { memo } from 'react';

export type SelectItemLabelProps = SelectItemProps;

const useStyles = makeStyles<SelectItemLabelProps>()({
  text: (props) => ['max-w-xs', props?.customTextColor]
});

export const SelectItemLabel: React.FC<SelectItemLabelProps> = memo(({ children, ...props }) => {
  const { checked, indeterminate, disabled, customTextColor } = { ...props };
  const styles = useStyles(props);

  return (
    <MetaText
      as="div"
      className={styles.text}
      disableColorStyle={Boolean(customTextColor)}
      enableHover="group"
      bold={checked}
      active={checked}
      disabled={disabled || indeterminate}>
      {children}
    </MetaText>
  );
});

SelectItemLabel.defaultProps = {
  disabled: false,
  indeterminate: false,
  checked: false,
  paddingY: false
};

SelectItemLabel.displayName = 'SelectItemLabel';
