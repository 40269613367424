import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TruckHeightIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_227)">
      <path
        d="M9.875 10.2917L8 12.375L6.125 10.2917"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.875 5.70833L8 3.625L6.125 5.70833"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0418 1.95834H1.9585"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0418 14.0417H1.9585"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_227">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TruckHeightIcon.displayName = 'TruckHeightIcon';

export { TruckHeightIcon };
