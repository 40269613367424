import { useToggle } from 'hooks/useToggle';
import React, { memo } from 'react';

import { TooltipProps } from './Tooltip';
import { TooltipNotification, TooltipNotificationProps } from './TooltipNotification';

type InfoTooltipProps = {
  message: string;
  showIcon?: boolean;
} & ParentClassNameProp &
  Pick<TooltipNotificationProps, 'manualPosition' | 'preRender' | 'hasOffset' | 'disabled'> &
  Pick<TooltipProps, 'isSquare' | 'isSmall'> &
  ChildrenProp;

const InfoTooltip: React.FC<InfoTooltipProps> = memo(
  ({
    children,
    showIcon,
    message,
    className,
    manualPosition,
    isSquare,
    isSmall,
    hasOffset,
    disabled
  }) => {
    const { enable: onHoverStart, disable: onHoverEnd, status } = useToggle();

    return (
      <TooltipNotification
        message={message}
        visible={status}
        disabled={disabled}
        showIcon={showIcon}
        manualPosition={manualPosition}
        isSquare={isSquare}
        hasOffset={hasOffset}
        isSmall={isSmall}
        className={className}>
        {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
        <div onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd} onMouseDown={onHoverEnd}>
          {children}
        </div>
      </TooltipNotification>
    );
  }
);

InfoTooltip.displayName = 'InfoTooltip';

export { InfoTooltip };
