import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const CommercialIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_275)">
      <path
        d="M2.7915 3.62501C2.7915 2.70454 3.5377 1.95834 4.45817 1.95834H11.5415C12.462 1.95834 13.2082 2.70454 13.2082 3.62501V11.5417C13.2082 12.0019 12.8351 12.375 12.3748 12.375H3.62484C3.1646 12.375 2.7915 12.0019 2.7915 11.5417V3.62501Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5835 6.54166C2.23832 6.54166 1.9585 6.26183 1.9585 5.91666C1.9585 5.57148 2.23832 5.29166 2.5835 5.29166"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4165 6.54166C13.7617 6.54166 14.0415 6.26183 14.0415 5.91666C14.0415 5.57148 13.7617 5.29166 13.4165 5.29166"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4585 13V14.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5415 13V14.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.375H13"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.37499V2.16666"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91683 9.66667C5.91683 9.89675 5.73028 10.0833 5.50016 10.0833C5.27005 10.0833 5.0835 9.89675 5.0835 9.66667C5.0835 9.43658 5.27005 9.25 5.50016 9.25C5.73028 9.25 5.91683 9.43658 5.91683 9.66667Z"
        stroke="currentColor"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9168 9.66667C10.9168 9.89675 10.7302 10.0833 10.5002 10.0833C10.2701 10.0833 10.0835 9.89675 10.0835 9.66667C10.0835 9.43658 10.2701 9.25 10.5002 9.25C10.7302 9.25 10.9168 9.43658 10.9168 9.66667Z"
        stroke="currentColor"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_275">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

CommercialIcon.displayName = 'CommercialIcon';

export { CommercialIcon };
