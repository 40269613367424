import { Listbox } from '@headlessui/react';
import { useKeydownEscapeListener } from 'hooks/useKeyboard';
import { PlaceSearchParseParamsQueryProps } from 'hooks/usePlaceSearch';
import React, { memo, ReactElement, ReactNode, useCallback } from 'react';
import { GenericQuery } from 'types/common';

import { DropdownListOption } from './DropdownListOption';

export type ListOption = {
  id?: string;
  value: string | number | ReactNode;
  buttonLabel?: ReactNode | string;
  itemLabel?: string;
  disabled?: boolean;
  customSelected?: boolean;
  query?: GenericQuery | PlaceSearchParseParamsQueryProps;
  initialValue?: boolean;
};

export type Props = {
  children?: ReactNode;
  options: ListOption[];
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
};

export const DropdownListOptions = memo(({ options, children, buttonRef }: Props) => {
  useKeydownEscapeListener({
    enable: true,
    callback: useCallback(
      (e: KeyboardEvent) => {
        e?.preventDefault();
        buttonRef?.current?.click();
      },
      [buttonRef]
    )
  });

  return (
    <>
      {options.map((option, index) => {
        return (
          <Listbox.Option as="li" key={index} value={option} disabled={option.disabled}>
            {({ active, selected }) => {
              if (children) {
                return React.cloneElement(children as ReactElement, { active, selected, option });
              }

              return (
                // Click function is handled by HeadlessUI's Listbox component
                <button className="w-full">
                  <DropdownListOption
                    active={active}
                    selected={selected}
                    value={option.value}
                    disabled={option.disabled}
                    itemLabel={option.itemLabel}
                  />
                </button>
              );
            }}
          </Listbox.Option>
        );
      })}
    </>
  );
});

DropdownListOptions.displayName = 'DropdownListOptions';
