import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { useContainerStyles } from './Checkbox';

export type Props = {
  type?: 'checkbox' | 'radio';
  checked?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles<Props>()({
  circleIcon: (props) => [
    'cursor-pointer',
    {
      'block w-1.5 h-1.5 bg-white rounded-xl': props.checked,
      hidden: !props.checked
    }
  ]
});

export const Radio = React.memo(({ type = 'radio', disabled = false, checked = false }: Props) => {
  const styles = useStyles({ type, checked, disabled });
  const containerStyles = useContainerStyles({ type, checked, disabled });

  return (
    <div className={containerStyles.container}>
      <div className={containerStyles.iconWrapper}>
        <div className={styles.circleIcon} />
      </div>
    </div>
  );
});

Radio.displayName = 'Radio';
