import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const HazmatIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_260)">
      <path
        d="M2.12693 11.628L6.51277 2.88048C7.12768 1.65401 8.87835 1.65433 9.49285 2.88102L13.8747 11.6286C14.4298 12.7367 13.624 14.0417 12.3845 14.0417H3.61682C2.37705 14.0417 1.57127 12.7362 2.12693 11.628Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.33334V8.00001"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99984 12.1667C8.46007 12.1667 8.83317 11.7936 8.83317 11.3333C8.83317 10.8731 8.46007 10.5 7.99984 10.5C7.5396 10.5 7.1665 10.8731 7.1665 11.3333C7.1665 11.7936 7.5396 12.1667 7.99984 12.1667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_260">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

HazmatIcon.displayName = 'HazmatIcon';

export { HazmatIcon };
