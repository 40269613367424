import { Listbox } from '@headlessui/react';
import { MetaText } from 'components/common/Typography';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import React, { useMemo, useRef } from 'react';
import { AsHeading } from 'types/common';

import { DropdownIconButton } from './DropdownIconButton';
import {
  DropdownListOptions,
  ListOption,
  Props as DropdownListOptionsProps
} from './DropdownListOptions';
import { DropdownOpenTransition } from './DropdownOpenTransition';

export type DropdownListProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (option: ListOption) => void;
  current?: ListOption;
  isSmall?: boolean;
  defaultLabel?: string;
  drawerClassName?: string;
  menuClosedClassName?: string;
} & AsHeading &
  DropdownListOptionsProps &
  ParentClassNameProp;

const useStyles = makeStyles<DropdownListProps>()({
  root: (props) => ['relative', 'w-full', 'cursor-pointer', props.className],
  menuOpen: [
    'border',
    'rounded-t-md',
    'border-primaryBorder border-b-transparent',
    'bg-white',
    'w-full',
    'filter shadow'
  ],
  menuClosed: ({ menuClosedClassName }) => [
    'border',
    'rounded-md',
    'border-primaryBorder ',
    'bg-white',
    'w-full',
    'filter shadow-sm hover:shadow',
    menuClosedClassName
  ],
  drawer: (props: DropdownListProps) => [
    'absolute z-20 w-full',
    'border',
    'rounded-b-md',
    'border-primaryBorder border-t-transparent',
    'focus-within:outline-none',
    'bg-primaryBackground',
    'w-full',
    'ml-[-1px]',
    'shadow-md',
    props?.drawerClassName
  ],
  button: (props: DropdownListProps) => [
    'w-full',
    'group',
    {
      'h-10': !props.isSmall
    }
  ]
});

export const DropdownList = React.memo(({ children, ...props }: DropdownListProps) => {
  const { options, onChange, defaultLabel, as, current } = props;
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const styles = useStyles(props);

  const selectedOption = useMemo(() => options?.find((option) => option.customSelected), [options]);
  const initialOption = useMemo(() => options?.find((option) => option.initialValue), [options]);

  const buttonLabel = useMemo(() => {
    return current ? (
      current.buttonLabel ?? current.value
    ) : selectedOption ? (
      selectedOption.buttonLabel ?? selectedOption.value
    ) : initialOption ? (
      initialOption.buttonLabel ?? initialOption.value
    ) : defaultLabel ? (
      <MetaText as={as} enableHover="group">
        {defaultLabel}
      </MetaText>
    ) : (
      <MetaText as={as} enableHover="group">
        {t('SELECT_ONE')}
      </MetaText>
    );
  }, [options, selectedOption, initialOption, defaultLabel, current]);

  return (
    <div className={styles.root}>
      <Listbox as="div" value={selectedOption} onChange={onChange}>
        {({ open }) => (
          <div className={open ? styles.menuOpen : styles.menuClosed}>
            <Listbox.Button ref={buttonRef} className={styles.button}>
              <DropdownIconButton label={buttonLabel} open={open} />
            </Listbox.Button>
            <DropdownOpenTransition open={open}>
              {/* We use native ul elements to allow keyboard tab on listbox contents */}
              <ul className={styles.drawer}>
                <DropdownListOptions options={options} buttonRef={buttonRef}>
                  {children}
                </DropdownListOptions>
              </ul>
            </DropdownOpenTransition>
          </div>
        )}
      </Listbox>
    </div>
  );
});

DropdownList.displayName = 'DropdownList';
