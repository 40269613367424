import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const DropdownIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <path
      d="M8 10L5 7H11L8 10Z"
      fill="#5E6D7D"
      stroke="#5E6D7D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

DropdownIcon.displayName = 'DropdownIcon';

export { DropdownIcon };
