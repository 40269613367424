import React, { memo } from 'react';

import { TooltipNotification, TooltipNotificationProps } from './TooltipNotification';

type Props = {
  message: string;
  visible: boolean;
  showIcon?: boolean;
} & ParentClassNameProp &
  Pick<TooltipNotificationProps, 'manualPosition'> &
  ChildrenProp;

const SuccessTooltip: React.FC<Props> = memo(
  ({ children, showIcon, message, visible, className, manualPosition }) => {
    return (
      <TooltipNotification
        manualPosition={manualPosition}
        className={className}
        isSuccess
        message={message}
        visible={visible}
        showIcon={showIcon}>
        {children}
      </TooltipNotification>
    );
  }
);

SuccessTooltip.displayName = 'SuccessTooltip';

export { SuccessTooltip };
