import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TruckIcon = React.memo((props: GlyphProps) => (
  <Glyph width={22} height={18} {...props}>
    <path
      d="M14 1H2C1.44772 1 1 1.44771 1 2V11C1 11.5523 1.44772 12 2 12H14C14.5523 12 15 11.5523 15 11V2C15 1.44772 14.5523 1 14 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15 16H7.5M3 16H2C1.44772 16 1 15.5523 1 15V12H14C14.5523 12 15 11.5523 15 11V7C15 6.44772 15.4477 6 16 6H17.382C17.7607 6 18.107 6.214 18.2764 6.55279L20.8944 11.7889C20.9639 11.9277 21 12.0808 21 12.2361V15C21 15.5523 20.5523 16 20 16H19"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <circle cx="17" cy="16" r="2" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="5" cy="16" r="2" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M21 21H1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Glyph>
));

TruckIcon.displayName = 'TruckIcon';

export { TruckIcon };
