import { IAuthLoginPayload } from '@truckmap/common';
import { atom } from 'recoil';
import { persistanceCacheEffect } from 'recoil/persistanceCacheEffect';
import { userAccessTokenEffect } from 'recoil/user/userAtomEffects';
import { truckMapConfig } from 'truckMapConfig';

export const userAtom = atom<IAuthLoginPayload>({
  key: 'userAtom',
  default: null,
  effects: truckMapConfig.isTenstreet
    ? [userAccessTokenEffect]
    : [persistanceCacheEffect.persistAtom, userAccessTokenEffect]
});
