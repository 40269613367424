import { ITrip } from '@truckmap/common';
import { PlaceContextType } from 'components/Place/PlaceDetails';
import { DashboardContextType } from 'hooks/useDashboard';
import { PlaceSearchContextType } from 'hooks/usePlaceSearch';
import { serializePage } from 'lib/contentful/contentfulSerializers';
import { createContext, useContext, useMemo } from 'react';
import { CareersPost } from 'types/api';
import { ErrorCodes } from 'types/common';
import { IPage } from 'types/generated/contentful';

export type PageContextCustomFields = {
  dashboardContext?: DashboardContextType;
  careers?: CareersPost[];
  placeContext?: PlaceContextType;
  placeSearchContext?: PlaceSearchContextType;
  statusCode?: ErrorCodes;
  currentTrip?: ITrip;
};

export type PageContextTypes = { page?: IPage } & PageContextCustomFields;

export function createPageContext() {
  return createContext<PageContextTypes>(null);
}

export const PageContext = createPageContext();

export const usePageContext = () => useContext(PageContext);

export const usePageSerializedContext = () => {
  const pageCtx = usePageContext();
  const { page, ...customFields } = pageCtx;

  const serializedPage = useMemo(() => page && serializePage(page), [page]);

  return useMemo(
    () => ({
      ...customFields,
      ...serializedPage
    }),
    [pageCtx]
  );
};
