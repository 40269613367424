import { ITruckOptionsItemKeys } from '@truckmap/common';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { TruckOptionsItemComponent } from 'components/TruckOptions/TruckOptionsItemComponent';
import { TruckOptionsNav } from 'components/TruckOptions/TruckOptionsNav';
import { useTruckOptions } from 'hooks/useTruckOptions/useTruckOptions';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

type TruckOptionsContentProps = ParentClassNameProp & {
  activeOption: ITruckOptionsItemKeys;
};

export enum truckOptionsList {
  equipmentHeights = 'equipmentHeightId',
  equipmentLengths = 'equipmentLengthId',
  equipmentWeights = 'equipmentWeightId',
  equipmentTypes = 'equipmentTypeId',
  equipmentCommercialTypes = 'equipmentCommercialTypeId',
  fuelTypes = 'fuelTypeId',
  hazmatClasses = 'hazmatClassId'
}

export enum truckOptionsListIds {
  equipmentTypeId = 'equipmentTypes',
  equipmentHeightId = 'equipmentHeights',
  equipmentLengthId = 'equipmentLengths',
  equipmentWeightId = 'equipmentWeights',
  equipmentCommercialTypeId = 'equipmentCommercialTypes',
  fuelTypeId = 'fuelTypes',
  hazmatClassId = 'hazmatClasses'
}

const useTruckOptionsContentStyles = makeStyles<TruckOptionsContentProps>()({
  root: (props) => [props.className, 'h-full md:h-[485px]'],
  sidePanel: ({ activeOption }) => [
    'pr-4',
    'sm:border-r border-neutralBorder',
    'w-full',
    'flex-grow sm:flex-grow-0',
    'sm:block',
    {
      'hidden sm:max-w-[240px]': activeOption
    }
  ],
  nav: 'w-full h-full min-w-[200px] md:h-[455px]',
  optionsComponent: ({ activeOption }) => [
    'flex-grow',
    'h-full md:max-h-[475px]',
    'pt-2',
    'sm:block',
    {
      hidden: !activeOption
    }
  ]
});

export const TruckOptionsContent: React.FC<TruckOptionsContentProps> = React.memo((props) => {
  const styles = useTruckOptionsContentStyles(props);
  const { truckOptionsPayload: data } = useTruckOptions();

  if (!data || !styles) return <></>;

  return (
    <div className={styles.root}>
      <PanelFlex align="stretch" fullWidth spacing="LG">
        <PanelFlex
          className={styles.sidePanel}
          wrap="nowrap"
          column
          justify="between"
          align="start">
          <TruckOptionsNav
            className={styles.nav}
            activeOption={props.activeOption}
            payload={data}
          />
        </PanelFlex>
        {props.activeOption && (
          <TruckOptionsItemComponent
            className={styles.optionsComponent}
            item={data.truckOptionsList[truckOptionsListIds[props.activeOption]]}
            name={props.activeOption as string}
          />
        )}
      </PanelFlex>
    </div>
  );
});

TruckOptionsContent.displayName = 'TruckOptionsContent';
