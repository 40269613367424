import { Skeleton, SkeletonProps } from 'components/common/Skeleton/Skeleton';
import { PanelFlex, PanelFlexProps } from 'components/layout/Panel/PanelFlex';
import { makeStyles } from 'lib/makeStyles';
import { memo, useMemo } from 'react';

type SkeletonGridProps = {
  columns?: 1 | 2 | 3 | 4;
  rows?: number;
} & Omit<SkeletonProps, 'type'> &
  ChildrenProp &
  PanelFlexProps;

const useSkeletonGridStyles = makeStyles<SkeletonGridProps>()({
  root: ({ columns, className }) => [
    'grid',
    'grid-rows-auto',
    'w-full h-full',
    {
      'grid-cols-1': columns === 1,
      'grid-cols-2': columns === 2,
      'grid-cols-3': columns === 3,
      'grid-cols-4': columns === 4
    },
    className
  ]
});

export const SkeletonGrid: React.FC<SkeletonGridProps> = memo(
  ({
    children,
    animate,
    width,
    height,
    columns = 1,
    rows = 4,
    padding = false,
    className = 'gap-2',
    ...flexPanelProps
  }: SkeletonGridProps) => {
    const styles = useSkeletonGridStyles({ columns, className });

    const skeletonGridContent = useMemo(() => {
      return [...Array(Math.round(rows * columns))].map((_, i) => (
        <PanelFlex key={`skeleton_grid_${i}`} padding={padding} {...flexPanelProps}>
          {children}
          <Skeleton animate={animate} width={width} height={height} type="grid" />
        </PanelFlex>
      ));
    }, [rows, columns]);

    return <div className={styles.root}>{skeletonGridContent}</div>;
  }
);

SkeletonGrid.displayName = 'SkeletonGrid';
