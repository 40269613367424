import { IAuthLoginPayload, removeAccessToken, setAccessToken } from '@truckmap/common';
import Cookies from 'js-cookie';
import { AtomEffect } from 'recoil';
import { truckMapConfig } from 'truckMapConfig';

export const userAccessTokenEffect: AtomEffect<IAuthLoginPayload> = ({
  node,
  resetSelf,
  getLoadable,
  trigger
}) => {
  if (trigger === 'get') {
    const userState = getLoadable(node).getValue();

    const userIsAuthenticated = Cookies.get(truckMapConfig.cookies.accessToken);
    const logoutCallback = Cookies.get(truckMapConfig.cookies.logoutCallback);

    if (
      logoutCallback ||
      (!userIsAuthenticated && userState) ||
      (userIsAuthenticated && !userState)
    ) {
      removeAccessToken();
      userIsAuthenticated && Cookies.remove(truckMapConfig.cookies.accessToken);
      logoutCallback && Cookies.remove(truckMapConfig.cookies.logoutCallback);
      userState && resetSelf();
    } else if (userState?.token?.token) {
      setAccessToken(userState.token.token);
    }
  }
};
