import { Translation } from 'components/common/Translation';
import { ClockIcon, Dollar2Icon, MapIcon, RouteIcon } from 'icons/iconic';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { FeatureBullet, FeatureBulletType } from './FeatureBullet';

export const authFeatures: FeatureBulletType[] = [
  {
    title: <Translation textKey="AUTH_FEATURE_BULLET_ONE" />,
    icon: ClockIcon,
    iconColor: 'text-brightBlue-600'
  },
  {
    title: <Translation textKey="AUTH_FEATURE_BULLET_TWO" />,
    icon: Dollar2Icon,
    iconColor: 'text-green-600'
  },
  {
    title: <Translation textKey="AUTH_FEATURE_BULLET_THREE" />,
    icon: MapIcon,
    iconColor: 'text-pink-600'
  },
  {
    title: <Translation textKey="AUTH_FEATURE_BULLET_FOUR" />,
    icon: RouteIcon,
    iconColor: 'text-indigo-700'
  }
];

const useStyles = makeStyles({
  root: ['grid grid-cols-2 grid-rows-2 text-center gap-x-6 gap-y-4 sm:gap-y-4', 'w-full']
});

export const FeatureBullets = React.memo(
  ({ features }: { features: FeatureBulletType[] } & ParentClassNameProp) => {
    const styles = useStyles();
    const featuresGrid = React.useMemo(
      () =>
        features.map((feature, index) => (
          <FeatureBullet key={`auth_feature_${index}`} {...feature} />
        )),
      [features]
    );

    return <div className={styles.root}>{featuresGrid}</div>;
  }
);

FeatureBullets.displayName = 'FeatureBullets';
