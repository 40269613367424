import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const TrailerLengthIcon = React.memo((props: GlyphProps) => (
  <Glyph width={16} height={16} {...props}>
    <g clipPath="url(#clip0_1925_246)">
      <path
        d="M10.2915 6.125L12.3748 8L10.2915 9.875"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.70833 6.125L3.625 8L5.70833 9.875"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.9585 1.95834V14.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0415 1.95834V14.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1925_246">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Glyph>
));

TrailerLengthIcon.displayName = 'TrailerLengthIcon';

export { TrailerLengthIcon };
