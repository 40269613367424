import { Dialog } from '@headlessui/react';
import { PanelFlex } from 'components/layout/Panel/PanelFlex';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { MetaText } from '../../Typography';
import { ModalProps } from '../Modal';

type ModalTitleProps = Pick<ModalProps, 'title'>;

const useStyles = makeStyles({
  header: ['border-b border-blue-050 rounded-t-md', 'cursor-default'],
  headerInner: ['flex justify-between items-center pb-2 pr-4']
});

export const ModalTitle: React.ComponentType<ModalTitleProps> = React.memo(({ title }) => {
  const styles = useStyles();

  return (
    <Dialog.Title className={styles.header}>
      <span className={styles.headerInner}>
        {typeof title === 'string' ? (
          <MetaText as="h2" bold>
            {title}
          </MetaText>
        ) : (
          title
        )}
      </span>
    </Dialog.Title>
  );
});

ModalTitle.displayName = 'ModalTitle';

export const ModalTitleWithIcon: React.FC<ModalTitleProps & { icon: React.ReactNode }> = ({
  title,
  icon
}) => {
  return (
    <PanelFlex spacing={false} padding={false} className="-mt-0.5">
      {icon}
      <MetaText as="h2" bold>
        {title}
      </MetaText>
    </PanelFlex>
  );
};
