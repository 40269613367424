import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { useFlexColumnLayoutStyles } from 'styles';

import { ModalProps } from '../Modal';

type ModalFooterProps = Pick<ModalProps, 'footer'>;

const useStyles = makeStyles({
  footerInner: ['border-t border-secondaryBorder']
});

export const ModalFooterWrapper: React.ComponentType<ModalFooterProps> = React.memo(
  ({ footer }) => {
    const styles = useStyles();
    const flexLayoutStyles = useFlexColumnLayoutStyles();

    return (
      <div className={flexLayoutStyles.flexShrink}>
        <div className={styles.footerInner}>{footer}</div>
      </div>
    );
  }
);

ModalFooterWrapper.displayName = 'ModalFooter';
