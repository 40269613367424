import { produce } from 'immer';
import { useRecoilCallback } from 'recoil';

import { autoCompleteAtom, AutoCompleteType } from './';

export const useSetAutoCompleteOpen = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      (open: boolean) => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            if (open !== draft.open) {
              draft.open = open;
            }
          })
        );
      },
    deps
  );

export const useShowAutoComplete = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.open = true;
          })
        );
      },
    deps
  );

export const useHideAutoComplete = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.open = true;
          })
        );
      },
    deps
  );

export const useToggleAutoComplete = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.open = !draft.open;
          })
        );
      },
    deps
  );

export const useSetAutoCompleteVisible = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      (visible: boolean) => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.visible = visible;
          })
        );
      },
    deps
  );

export const useSetAutoCompleteValue = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      (proxiedValue: string | React.ChangeEvent<HTMLInputElement>) => {
        const value = typeof proxiedValue === 'string' ? proxiedValue : proxiedValue.target.value;

        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.value = value;

            if (!draft.open) {
              draft.open = true;
            }
          })
        );
      },
    deps
  );

export const useClearAutoComplete = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.value = '';
          })
        );
      },
    deps
  );

export const useResetAutoComplete = (type: AutoCompleteType, callback?: () => void, deps = []) =>
  useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(autoCompleteAtom(type));
        callback && callback();
      },
    deps
  );

export const useToggleMobileAutoComplete = (type: AutoCompleteType, deps = []) =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          autoCompleteAtom(type),
          produce((draft) => {
            draft.open = false;
            draft.visible = !draft.visible;
          })
        );
      },
    deps
  );
