import { useCallback, useMemo, useState } from 'react';

import { ListOption } from './DropdownListOptions';

const useDropdownListOptions = (options: ListOption[]) => {
  const [dropdownListOptions, setDropdownListOptions] = useState<ListOption[]>(options ?? null);

  const onChange = useCallback(
    (selectedOption: ListOption) => {
      const updatedOptions: ListOption[] = dropdownListOptions.map((option) => {
        option === selectedOption
          ? (option.customSelected = true)
          : (option.customSelected = false);
        return option;
      });

      setDropdownListOptions(updatedOptions);
    },
    [options]
  );

  return useMemo(() => {
    return {
      onChange,
      dropdownListOptions
    };
  }, [dropdownListOptions, options]);
};

export { useDropdownListOptions };
