import { MetaText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

export type FeatureBulletType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  title: React.ReactNode;
  iconColor?: string;
};

const useStyles = makeStyles({
  root: 'w-[140px] mx-auto sm:mx-0',
  leftCol: ['flex flex-col'],
  rightCol: ['w-full h-full overflow-hidden cols-2 relative'],
  mapFadeImage: 'absolute bottom-0 -right-60 w-full h-[400px]',
  grid: 'relative grid grid-cols-2 w-full h-[500px] overflow-hidden',
  icon: ({ iconColor }: FeatureBulletType) => ['h-8 w-8', iconColor]
});

export const FeatureBullet = React.memo(({ icon, title, iconColor }: FeatureBulletType) => {
  const styles = useStyles({ iconColor });
  const Icon = icon;
  return (
    <PanelItem className={styles.root} column>
      <Icon className={styles.icon} />
      <MetaText>{title}</MetaText>
    </PanelItem>
  );
});

FeatureBullet.displayName = 'FeatureBullet';
