import { fetchRuntimeFeatureFlags } from 'clients/fetchRuntimeFeatureFlags';
import React from 'react';
import useSWRImmutable from 'swr/immutable';
import { truckMapConfig } from 'truckMapConfig';
import { featureFlag, FeatureFlagKey } from 'truckMapConfig/featureFlags';

type FeatureEnabledProps = {
  name: FeatureFlagKey;
} & ChildrenProp;

export const StaticFeatureEnabled: React.ComponentType<FeatureEnabledProps> = React.memo(
  ({ name: featureName, children }) => (featureFlag[featureName] ? <>{children}</> : null)
);

StaticFeatureEnabled.displayName = 'FeatureEnabled';

export const RuntimeFeatureEnabled: React.ComponentType<FeatureEnabledProps> = React.memo(
  ({ name: featureName, children }) => {
    if (!truckMapConfig.isClientSideRender) {
      return null;
    }

    const { data: runtimeFeatureFlag, error } = useSWRImmutable(
      'runtime-feature',
      async () => {
        const response = await fetchRuntimeFeatureFlags();

        if (response.status !== 200) {
          console.warn(`getRuntimeFeatureFlags response is ${response.status}`);
        }

        return response.data;
      },
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: true
      }
    );

    if (error) {
      console.error(error);
    }

    return !error && runtimeFeatureFlag?.includes(featureName) ? <>{children}</> : null;
  }
);

RuntimeFeatureEnabled.displayName = 'RuntimeFeatureEnabled';

export const isTenstreetFeatureEnabled = truckMapConfig.isStaging && truckMapConfig.isTenstreet;

export const TenstreetFeatureEnabled: React.ComponentType<ChildrenProp> = React.memo(
  ({ children }) => (isTenstreetFeatureEnabled ? <>{children}</> : null)
);

TenstreetFeatureEnabled.displayName = 'TenstreetFeatureEnabled';
